@import '../../styles/config';

.drawer {
	--transition-speed: 0.3s;
}

.drawer-content {
	position: fixed;
	z-index: $layer_7;
	width: 95%;
	height: 100%;
	max-width: 900px;
	padding: 15px 30px;
	background: #fff;
	overflow: auto;
	transition: transform var(--transition-speed) ease-in-out;

	&.top {
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 40%;
		transform: translateY(-100%);
	}

	&.right {
		top: 0;
		right: 0;
		transform: translateX(100%);
	}

	&.bottom {
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 40%;
		transform: translateY(100%);
	}

	&.left {
		top: 0;
		left: 0;
		transform: translateX(-100%);
	}
}

@media only screen and (min-width: 680px) {
	.drawer-content {
		width: 75%;
	}
}

.drawer-content__header {
	padding: 5px 0;
	text-align: right;
}

.drawer-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $layer_0;
	width: 100%;
	height: 100%;
	background-color: rgba(88, 88, 88, 0.85);
	transition: opacity var(--transition-speed) ease,
		visibility var(--transition-speed) ease;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

.drawer--open {
	&.drawer--in {
		.drawer-content {
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
		}

		.left, .right {
			transform: translateX(0);
		}

		.top, .bottom {
			transform: translateY(0);
		}

		.drawer-backdrop {
			z-index: $layer_6;
			visibility: visible;
			opacity: 1;
			pointer-events: auto;

		}
	}
}