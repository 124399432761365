.image-progress {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 500px;
	max-height: 375px;
	padding: 45px 0;
	margin: 0 auto;
	background-color: rgba(218, 218, 218, 1);
}

.image-progress__label {
	position: relative;
	width: 100%;
	min-height: 45px;
}

.image-progress__bar {
	position: absolute;
	right: 0;
	left: 0;
	width: 75%;
	margin: auto;
}
