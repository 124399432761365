@import '../../styles/config';

.notice {
	margin: 20px 0 40px;
	padding: 20px;
	color: #222b3e;
	font-weight: bold;
	background-color: fade-out($color_green, 0.85);
	border: 2px solid $color_green;
	border-radius: 5px;

	p {
		margin: 0 0 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.notice--warning {
	background-color: fade-out($color_red, 0.85);
	border-color: $color_red;
}
