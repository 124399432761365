@import '../../styles/config';

.edit-form {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 18px;
}

.edit-form__item {
	margin-bottom: 20px;
}

.edit-form__label {
	display: block;
	margin-bottom: 5px;
	font-size: 14px;
}

.edit-form__field {
	width: 100%;
	padding: 15px;
	line-height: 1;
	border: 1px solid #c7c7c7;
}

.edit-form__field--error {
	border-color: $color_red;
	box-shadow: fade-out($color_red, 0.85) 0 0 5px 2px;

	&:focus-visible {
		outline: 1px solid $color_red;
		border-radius: 1px;
	}
}

.edit-form__note {
	font-size: 12px;
}

.edit-form__actions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	column-gap: 10px;
	row-gap: 10px;
	margin-top: 40px;
}

.edit-form__value {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 10px;
}

.edit-form__delete {
	margin-top: 45px;
}

@media only screen and (min-width: 680px) {
	.edit-form__actions {
		column-gap: 15px;
	}
}
