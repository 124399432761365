.tag-list {
	display: flex;
	flex-direction: row;
	column-gap: 8px;
}

.tag {
	padding: 4px 16px;
	color: #222b3e;
	font-size: 12px;
	font-weight: normal;
	background-color: rgba(218, 218, 218, 0.6);
}
