@import '../../styles/config';

.dropzone {
	position: relative;
	margin-bottom: 30px;
}

.dropzone-area {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.dropzone--image {
	width: 100%;
	margin-bottom: 0;

	.dropzone-area {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: $layer_6;
		margin: 8px;
		background-color: rgba(255, 255, 255, 0.85);
		border-width: 2px;
		border-style: dashed;
		border-color: #585858;

		&::before {
			content: '';
			position: absolute;
			top: -9px;
			right: -9px;
			bottom: -9px;
			left: -9px;
			border-width: 8px;
			border-style: style;
			border-color: transparent;
		}
	}

	.dropzone__label {
		font-size: 14px;
	}
}

.dropzone__label {
	margin-top: 10px;
	font-weight: bold;
	text-align: center;
}
