@import '../../styles/config';

.read-only {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $layer_9;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.9);
}

.read-only__message {
	padding: 15px;
	width: 90%;
	max-width: 600px;
	background-color: #fff;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
}

.read-only__title {
	margin: 0;
	font-size: 22px;
}

.read-only__actions {
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	column-gap: 15px;
}

@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
	.read-only {
		background-color: transparent;
		backdrop-filter: saturate(160%) blur(10px);
	}
}

@media only screen and (min-width: 860px) {
	.read-only__message {
		padding: 30px 45px;
	}
}
