@import '../../styles/config';

.sample-image {
	position: relative;
	width: 100%;
}

.sample-image__overlay {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	z-index: $layer_3;
	margin: auto;
	color: #fff;
	font-size: 48px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	transform: rotate(-30deg) translateY(-50%);
}

.sample-image__image {
	display: block;
	width: 100%;
	height: auto;
	filter: grayscale(1);
}

.no-image {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-height: 375px;
	background-color: rgba(218, 218, 218, 1);
	aspect-ratio: 1.3333 / 1;

	svg {
		display: block;
	}
}

.no-image__text {
	margin: 0;
	padding: 0;
	font-size: 20px;
	font-weight: bold;
}

.truck-image__photo-wrapper {
	position: relative;
	align-self: flex-start;
}

.truck-image__photo-hover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: opacity 0.2s ease-out;
	cursor: pointer;
}

.truck-image__expand {
	width: 100%;
	height: 100%;
	background: none;
	border: none;
	cursor: pointer;
}

.truck-image__photo-wrapper:hover {
	.truck-image__photo-hover {
		opacity: 1;
	}
}

.truck-image__photo {
	display: block;
	max-width: 100%;
	height: auto;
}

.truck-image__status {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: $layer_2;
	padding: 8px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	background-color: rgba(88, 88, 88, 1);
	text-transform: uppercase;
	text-align: center;

	&--retake, &--alert {
		background-color: $color_red;
	}

	&--uploaded, &--appraisal, &--info {
		background-color: $color_green;
	}

	&--approved {
		background-color: rgba(72, 104, 207, 1);
	}
}

.truck-image__media {
	position: relative;
	display: flex;
	flex: 1;
}

.truck-image__media--grid {
	flex: unset;
	min-height: auto;

	&::before {
		padding-bottom: 75%;
		content: '';
		float: left;
	}

	&::after {
		display: table;
		content: '';
		clear: both;
	}

	.truck-image__photo-wrapper {
		width: 100%;
	}
}

.truck-image__placeholder {
	display: block;
	position: absolute;
	max-width: 100%;
	height: auto;
}

.truck-image__progress {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $layer_7;
	margin: 0;

	& > div {
		height: 100%;
	}
}

.truck-image__actions {
	display: flex;
	flex-direction: row;
	column-gap: 15px;
	padding: 10px 10px 5px;

	&--hidden {
		visibility: hidden;
	}
}
