@import '../../styles/config';

.inventory-actions {
	display: flex;
	align-items: center;
	column-gap: 10px;
	gap: 15px;
	margin: 15px 0 45px;
}

.inventory-section {
	margin: 45px 0;
}

.inventory-section--padded {
	margin: 0;
	padding: 45px 0;
}

.inventory-section__header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.inventory-section__title {
	margin-top: 10px;
	margin-right: 20px;
}

.inventory-section__actions {
	margin-top: 10px;
}

.photo-grid {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
}

.photo-grid--multi {
	margin-top: 20px;
}

@media only screen and (min-width: 480px) {
	.photo-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px;
	}
}

@media only screen and (min-width: 740px) {
	.photo-grid {
		grid-template-columns: repeat(3, 1fr);
	}
	.inventory-actions {
		flex-wrap: wrap;
	}
}

@media only screen and (min-width: 1100px) {
	.photo-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
	}
}

.truck-image__detail {
	font-size: 12px;
}

.truck-image__alt {
	margin-top: 15px;

	label {
		display: block;
		margin-bottom: 3px;
		font-size: 14px;
	}

	input {
		width: 100%;
		padding: 8px;
		background-color: #fff;
		border: 1px solid #c7c7c7;
		border-radius: 3px;
	}
}

.truck-image__footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 10px;
	margin-top: auto;
	padding: 5px 10px 10px;
}

.truck-image__check {
	flex: 1;
}

.selected-note {
	margin-left: 5px;
	font-size: 12px;
	text-transform: uppercase;
	opacity: 0.2;
	transition: opacity 0.2s ease-in-out;

	&--active {
		opacity: 1;
	}
}

.truck-image__alert {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 5px;
	color: $color_red;
	font-size: 12px;
	text-transform: uppercase;
}
