@import '../../styles/config';

.edit {
	position: relative;
}

.edit__loader {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $layer_8;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	background-color: rgba(255, 255, 255, 0.4);
}

.edit__media {
	min-height: 300px;
	margin-bottom: 45px;
}

.edit--loading {
	.edit__inner {
		opacity: 0.5;
	}
}

.edit__title {
	margin: 0;
	padding: 0;
	font-size: 18px;
	font-weight: normal;
}

.edit__header {
	margin: 0 0 45px;
}

.edit__location {
	margin: 15px 0 5px 0;
	padding: 0;
	font-size: 28px;
	font-weight: bold;
}

.edit__template {
	font-size: 14px;
}
