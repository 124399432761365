@import '../../styles/config';

.filter-dropdown {
	position: relative;
	display: inline-block;
	padding: 5px 15px;
	font-size: 14px;
	background-color: rgba(218, 218, 218, 0.15);
	border: 1px solid rgba(34, 43, 62, 1);
	border-radius: 100px;
	transition: color 0.2s ease-in, background-color 0.2s ease-in-out;
	cursor: pointer;

	path {
		transition: fill 0.2s ease-in-out;
	}
}

.filter-dropdown:hover, .filter-dropdown--active {
	color: #fff;
	background-color: $color_red;
	border-color: $color_red;

	path {
		fill: #fff;
	}
}

.filter-dropdown--set {
	border-color: $color_red;
	outline: 1px solid $color_red;
	box-shadow: 0 0 12px 0 fade-out($color_red, 0.85);
}

.filter-dropdown__label {
	display: inline-flex;
	align-items: center;
	column-gap: 10px;
}

.filter-dropdown__options {
	position: absolute;
	bottom: -2px;
	left: 0;
	z-index: $layer_7;
	width: 175px;
	padding: 15px 10px 10px;
	background-color: #fff;
	border: 1px solid rgba(199, 199, 199, 1);
	transform: translateY(100%);
}

.filter-dropdown__option {
	margin: 8px 0;
	color: rgba(34, 43, 62, 1);
	font-size: 16px;
}

.filter-dropdown__option--inactive {
	opacity: 0.3;
}

.filter-dropdown__backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $layer_5;
	background-color: rgba(255, 255, 255, 0);
}