@import '../../styles/config';

.wizard__title {
	margin: 0;
	padding: 0;
	font-size: 18px;
	font-weight: normal;
}

.wizard-step {
	margin-bottom: 90px;
}

.wizard-step__header {
	margin: 0 0 45px;
}

.wizard-step__name {
	margin: 0;
	padding: 0;
	font-size: 28px;
	font-weight: bold;
}

.wizard-step__progress {
	font-size: 14px;
}

.wizard-step__actions {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 15px;
	margin-top: 20px;
}

.wizard-step__next {
	margin-left: auto;
	font-size: 14px;
}

.wizard-step__next-up {
	margin-top: 15px;
}

.wizard-step__select {
	padding: 8px;
	background-color: #fff;
	border: 1px solid #000;
}

.wizard-step__upload {
	display: flex;
	flex-direction: column;
	min-height: 350px;
}

.wizard-step__dropzone {
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
}

.wizard-content {
	margin: 60px 0;
}

.wizard-samples {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
}

@media only screen and (min-width: 620px) {
	.wizard-samples {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

@media only screen and (min-width: 820px) {
	.wizard-samples {
		grid-template-columns: repeat(3, 1fr);
	}
}

.wizard-samples__image {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.wizard__loader {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $layer_8;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	background-color: rgba(255, 255, 255, 0.4);
}
