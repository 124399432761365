.form {
  display: flex;
  gap: 1rem;
}

@media only screen and (max-width: 740px) {
  .form {
    flex-direction: column;
  }
}
